import {
	type MetaFunction,
	redirect,
	type LoaderFunctionArgs,
} from '@remix-run/node'

import { getUserId } from '#app/utils/auth.server.ts'

// This loader function will run before the component is rendered
// It will check if the user is logged in and redirect them to the tasklist page if they are

export async function loader({ request }: LoaderFunctionArgs) {
	const userId = await getUserId(request)
	if (userId) {
		return redirect(`/tasklist`)
	} else {
		return null
	}
}

export const meta: MetaFunction = () => [{ title: 'Today' }]

export default function Index() {
	return (
		<main className="font-poppins grid h-full place-items-center">
			<div className="grid place-items-center px-4 py-16 xl:grid-cols-2 xl:gap-24">
				<div className="flex max-w-md flex-col items-center text-center xl:order-2 xl:items-start xl:text-left">
					<h1
						data-heading
						className="mt-8 animate-slide-top text-4xl font-medium text-foreground [animation-delay:0.3s] [animation-fill-mode:backwards] md:text-5xl xl:mt-4 xl:animate-slide-left xl:text-6xl xl:[animation-delay:0.8s] xl:[animation-fill-mode:backwards]"
					>
						<a href="/">Today</a>
					</h1>
				</div>
			</div>
		</main>
	)
}
